<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 기계분류 -->
          <c-select
            type="search"
            codeGroupCd="HHM_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="machineryClassification"
            label="LBL0002500"
            v-model="searchParam.machineryClassification"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-hazard-equip
            :editable="editable"
            :plantCd="searchParam.plantCd"
            label="유해위험기계기구"
            name="hhmHazardousMachineryId"
            v-model="searchParam.hhmHazardousMachineryId">
          </c-hazard-equip>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 검사기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-1M"
            defaultEnd="1M"
            label="검사기간"
            name="period"
            v-model="searchParam.period"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="유해위험기계기구별 검사결과 목록"
      tableId="hazardEquipmentHis"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :usePaging="false"
      :filtering="false"
      :checkClickFlag="false"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
        <q-btn-group outline>
          <c-btn v-if="editable" label="LBL0002506" icon="upload"  @btnClicked="openExcelUploader"/>
          <!-- 등록 -->
          <c-btn label="LBLREG" v-if="editable" icon="add" @btnClicked="linkClick" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['inspectionSubjectFlag']">
          <q-chip
            v-if="props.row.inspectionSubjectFlag==='A'"
            :color="setTagColor(props.row.inspectionSubjectFlag)"
            outline square
            class="full-size-chip inspectionSubjectFlag-blinking"
            text-color="white"
            icon="alarm">
            {{setTagName(props.row.inspectionSubjectFlag)}}
          </q-chip>
          <q-chip
            v-else
            :color="setTagColor(props.row.inspectionSubjectFlag)"
            outline square
            class="full-size-chip"
            text-color="white">
            {{setTagName(props.row.inspectionSubjectFlag)}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "hazard-equipment-result-register",
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'hhmHazardousMachineryId' },
          { index: 1, colName: 'hhmHazardousMachineryId' },
          { index: 2, colName: 'hhmHazardousMachineryId' },
          { index: 3, colName: 'hhmHazardousMachineryId' },

        ],
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            style: 'width:80px', 
            align: "center",
            sortable: true,
          },
          {
            name: "machineryName",
            field: "machineryName",
            // 기계명
            label: "LBL0002507",
            style: 'width:200px', 
            align: "left",
            sortable: true,
          },
          {
            name: "inspectionSubjectFlag",
            field: "inspectionSubjectFlag",
            // 검사상태
            label: "LBL0002508",
            type: 'custom',
            style: 'width:70px', 
            align: "center",
            sortable: true,
          },
          {
            name: "itemNo",
            field: "itemNo",
            // item 번호
            label: "LBL0002517",
            style: 'width:150px', 
            align: "center",
            sortable: true,
          },
          {
            name: "inspectionDate",
            field: "inspectionDate",
            // 검사일
            label: "LBL0002552",
            style: 'width:120px',
            align: "center",
            sortable: false,
            type: "link",
          },
          {
            name: "departmentDeptName",
            field: "departmentDeptName",
            // 주관부서
            label: "LBL0002524",
            style: 'width:120px',
            align: "center",
            sortable: false,
          },
          {
            name: "inspectionAgency",
            field: "inspectionAgency",
            // 기관(검사원명)
            label: "LBL0002553",
            style: 'width:120px',
            align: "left",
            sortable: false,
          },
          {
            name: "inspectionResultDetail",
            field: "inspectionResultDetail",
            // 검사결과 상세
            label: "LBL0002554",
            style: 'width:350px',
            align: "left",
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        machineryClassification: null,
        period: [],
      },
      editable: true,
      colorItems: [
        { stepperMstCd: 'Y', stepperMstNm: '대상', colorClass: 'green' },
        { stepperMstCd: 'N', stepperMstNm: '비대상', colorClass: 'orange' },
        { stepperMstCd: 'A', stepperMstNm: '지연', colorClass: 'red' },
      ],
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.hhm.hazard.history.list.url;
      // list setting
      this.getList();
    },
    getList() {
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "LBL0002515"; // 유해위험기계기구 상세
      this.popupOptions.param = {
        inspectionNo: row ? row.inspectionNo : '',
      };
      this.popupOptions.target = () =>
        import(`${"./hazardEquipmentResultRegisterDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.height = '300px';
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
    openExcelUploader() {
      this.popupOptions.title = '유해위험기계기구별 검사결과 업로드';
      this.popupOptions.param = {}
      this.popupOptions.target = () => import(`${'./hazardResultExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result, plantCd) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
          item.departmentUserId = this.$store.getters.user.userId;  
          item.plantCd = plantCd;
          item.editFlag = 'C';
        })
        this.$http.url = transactionConfig.sop.hhm.hazard.check.uploadSave.url;
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    }
  },
};
</script>
<style>
.inspectionSubjectFlag-blinking {
  -webkit-animation:inspectionSubjectFlag-blink 3s ease-in-out infinite alternate;
  -moz-animation:inspectionSubjectFlag-blink 3s ease-in-out infinite alternate;
  animation:inspectionSubjectFlag-blink 3s ease-in-out infinite alternate;
}
@-webkit-keyframes inspectionSubjectFlag-blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes inspectionSubjectFlag-blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes inspectionSubjectFlag-blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>